<template>
    <b-modal 
      :id="detail.id.toString()"
      centered
      hide-header
      ref="jobDetail"
      @show= "viewJob"
      hide-footer
      >
      <v-wait for="fetchingJobDetail">
        <template slot="waiting">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="20"></content-placeholders-text>
          </content-placeholders>
        </template>
        <b-card class="mb-0">
        <span class="fa fa-close fa-lg px-1 pull-right" @click="hideModal"></span>
        <b-card-title>{{job_detail.job_title}}
        </b-card-title>
        <b-card-sub-title>
          Posted on {{job_detail.post_date}}
        </b-card-sub-title>
        <div class="my-3"><b>Company : </b> {{job_detail.company_name}}</div>
        <div class="my-3"><b>Job Location : </b> {{job_detail.job_location}}</div>
        <h6 class="mb-3"><b>Job Description :</b></h6>
          <b-card-text class="job_detail">
            {{job_detail.job_description}}
          </b-card-text>
        </b-card>
      </v-wait>
    </b-modal>
</template>
<script>

export default {
  name: 'JobDetail',
  data() {
    return {
        job_detail:{},
    }
  },
  props: {
    detail: {
      type: Object
    }
  },
  methods: {
    viewJob(){
      this.$wait.start('fetchingJobDetail')
      this.job_detail = {}
      this.$http
        .get('/api/job_search/job_detail', {
        params: { id: this.detail.id},
        handleErrors: true
        })
        .then(res => {
          this.job_detail = res.data
          this.$wait.end('fetchingJobDetail')
        })
    },
    hideModal() {
      this.$refs.jobDetail.hide()
    }
  }
}
</script>
<style type="scss">
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  .job_detail{
    overflow-y: auto;
    max-height: 50vh;
    margin-right: -20px;
    padding-right: 15px;
  }
</style>
