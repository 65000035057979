<template>
  <b-modal
    :id="'uploadInstitutions_' + query.id"
    ref="uploadInstitutions"
    size="lg"
    modal-class="custom-peer-group"
    @show="setInitData"
    body-class="cpg-body"
    hide-header
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
  >
    <div class="mx-3">
      <b-row class="border-bottom mb-3">
        <h4 class="pb-2 col">Upload Institutions</h4>
        <label v-if="query.count" class="pull-right"
          >Uploaded Institutions :
          <b class="px-2 mr-5">{{ query.count }}</b></label
        >
        <span
          class="fa fa-close fa-lg px-1 pull-right"
          @click="closeModel"
        ></span>
      </b-row>
      <div class="row pl-3 mb-2">
        <p>
          Use the <b>Choose File</b> button below to select a csv file
          containing the list of <b>Federal Reserve RSSD IDs</b>.
        </p>

        <p>
          The file should contain a <b>single column of RSSD ID numbers</b>. A
          column header is optional.
        </p>

        <p>The file must have the extension <b>.csv</b></p>
      </div>
      <div class="row justify-content-center">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-12">
              <input
                type="file"
                accept="text/csv"
                name="fiFile"
                id="fiFile"
                class="inputfile"
                ref="fiFile"
                @change="handleFileUpload()"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row pl-3 mb-2 mt-2 pt-2">
        <span class="pr-1"><b>Uploaded Institutions:</b></span>
        <span>{{ uploadedInstitutions }}</span>
      </div>
      <div class="flex-row align-items-top">
        <div class="container">
          <b-row class="list-group-container">
            <b-col sm="2"> </b-col>
            <b-col sm="8" class="text-center">
              <b-button
                size
                variant="secondary"
                class="fw-btn mx-3"
                @click="closeModel"
                >Close</b-button
              >
              <b-button
                size=""
                variant="primary"
                class="fw-btn-ext mx-3"
                :disabled="!validFile || submittingForm"
                @click="saveUploadedList"
                >Save Uploaded List</b-button
              >
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'selectedInstitutions',
  components: {},
  props: {
    query: {
      type: Object
    },
    rule: {
      type: Object
    }
  },
  data() {
    return {
      uploadedIds: [],
      selectedInstitutionIds: [],
      submittingForm: false,
      validFile: false
    }
  },
  computed: {
    validFormats() {
      return ['text/csv']
    },
    uploadedInstitutions() {
      return this.uploadedIds.length
    }
  },
  methods: {
    setInitData() {},
    handleFileUpload() {
      let chosenFile = this.$refs.fiFile.files[0]

      if (this.validFormats.includes(chosenFile.type)) {
        this.validFile = true
        this.fiFile = chosenFile
        const reader = new FileReader()

        reader.readAsText(this.fiFile)

        reader.onload = () => {
          let data = reader.result
          let arr = data.split(/\r?\n/)
          let columns = arr[0].split(',')
          if (columns.length > 1) {
            this.$toasted.show(
              'Invalid file format, Please make sure file has only one column (RSSD ID)',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
            this.clearData()
            // this.fiFile = null
            // this.validFile = false
          } else {
            let result = arr.map(id => {
              if (!isNaN(parseInt(id))) return parseInt(id)
            })
            if (result && result[0] === undefined) result.shift()
            this.uploadedIds = result
            this.validFile = true
          }
        }
      } else {
        this.$toasted.show('Invalid file format, please choose csv files...', {
          icon: 'chain-broken',
          type: 'error'
        })
        this.clearData()
        // this.fiFile = null
        // this.validFile = false
      }
    },
    validateIds() {
      // set selectedInstitutionIds here after validation
      this.$http
        .post(
          '/api/financial_institutions/validate?ids[]=' +
            this.uploadedIds.join('&ids[]='),
          {
            handleErrors: true
          }
        )
        .then(res => {
          this.selectedInstitutionIds = res.data.financial_institutions
          this.query.value = this.selectedInstitutionIds
          this.query.count = this.selectedInstitutionIds.length
        })
    },
    clearData() {
      this.fiFile = null
      this.validFile = false
      this.selectedInstitutionIds = []
      this.uploadedIds = []
    },
    closeModel() {
      this.clearData()
      this.$refs.uploadInstitutions.hide()
    },
    saveUploadedList() {
      this.submittingForm = true
      this.validateIds()
      this.submittingForm = false
      this.closeModel()
    }
  }
}
</script>
