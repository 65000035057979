<template>
  <b-modal
    :id="'selectInstitutions_' + query.id"
    ref="selectInstitutions"
    size="lg"
    modal-class="custom-peer-group"
    @show="setInitData"
    body-class="cpg-body"
    hide-header
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
  >
    <div class="mx-3">
      <b-row class="border-bottom mb-3">
        <h4 class="pb-2 col">Select Institutions</h4>
        <label v-if="query.count" class="pull-right"
          >Matching Institutions :
          <b class="px-2 mr-5">{{ query.count }}</b></label
        >
        <span
          class="fa fa-close fa-lg px-1 pull-right"
          @click="closeModel"
        ></span>
      </b-row>
      <div>
        <v-wait for="fetchingFIData">
          <template slot="waiting">
            <content-placeholders :rounded="true">
              <content-placeholders-text
                :lines="20"
              ></content-placeholders-text>
            </content-placeholders>
          </template>
          <div class="row justify-content-center">
            <div class="col-12">
              <b-list-group
                class="no-border"
                :class="{ loading: this.loading }"
              >
                <div class="row pl-3 mb-2">
                  Use the search tool below to select financial institutions.
                  Search by Name, City, State, or Federal Reserve ID.
                </div>
                <div class="row">
                  <b-form class="d-flex search-form" autocomplete="off">
                    <div class="col-sm-2" v-if="!isMortgageLender">
                      <b-form-group>
                        <label for="fi_type" class="mb-1">
                          <b>FI Type</b>
                        </label>
                        <b-form-select
                          v-model="searchFTType"
                          @input="searchInstitutions"
                        >
                          <option
                            :value="fi_type.code"
                            v-for="(fi_type, index) in fi_types"
                            :key="'1_' + index"
                            >{{ fi_type.name }}</option
                          >
                        </b-form-select>
                      </b-form-group>
                    </div>
                    <div class="col-sm-5 pl-0">
                      <b-form-group>
                        <label for="Search" class="mb-1">
                          <b>Search</b>
                        </label>
                        <b-form-input
                          type="text"
                          ref="searchInstitution"
                          class="form-control rounded"
                          placeholder="FI Name or Federal Reserve ID"
                          v-model="searchTextField"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-sm-3 pl-0">
                      <b-form-group>
                        <label for="City" class="mb-1">
                          <b>City</b>
                        </label>
                        <b-form-input
                          type="text"
                          id="city"
                          class="form-control rounded"
                          placeholder="Enter City (optional)"
                          v-model="searchCityField"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-sm-2 pl-0">
                      <b-form-group>
                        <label for="State" class="mb-1">
                          <b>State</b>
                        </label>
                        <b-form-select
                          v-model="searchState"
                          @input="searchInstitutions"
                        >
                          <template slot="first">
                            <option :value="null">State (optional)</option>
                          </template>
                          <option
                            :value="state.code"
                            v-for="(state, index) in states"
                            :key="'2_' + index"
                            >{{ state.name }}</option
                          >
                        </b-form-select>
                      </b-form-group>
                    </div>
                  </b-form>
                </div>

                <div class="row list-group-container">
                  <div class="col-12">
                    <div class="row card-sub-header mt-0">
                      <div class="col-sm-6 card-sub-header">
                        <span v-if="institutions.length">
                          Search Results:
                          <i>{{ institutions.length | numberFormat }} FIs</i>
                        </span>
                        <span v-else>
                          Search Results
                        </span>
                      </div>
                      <div class="col-sm-6 text-right pr-0 align-self-center">
                        <b-button
                          size="sm"
                          variant="primary"
                          @click="addAll"
                          class="mx-3 font-weight-bold"
                          >Add All</b-button
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="full-border">
                      <v-wait for="fetchingFIs">
                        <template slot="waiting">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="7"
                            ></content-placeholders-text>
                          </content-placeholders>
                        </template>
                        <div
                          class="filter-result"
                          v-on:scroll.passive="fetchMoreData($event)"
                        >
                          <b-card class="text-center" body-class="p-0">
                            <b-row>
                              <b-col md="12">
                                <div>
                                  <b-card class="text-left">
                                    <b-list-group
                                      v-if="emptyInstitutions"
                                      class="text-center"
                                    >
                                      <b-list-group-item>
                                        <i>No Institutions found ...</i>
                                      </b-list-group-item>
                                    </b-list-group>
                                    <b-list-group v-else>
                                      <div
                                        v-for="institution in institutions"
                                        :key="'3_' + institution.id"
                                      >
                                        <b-list-group-item
                                          @click="setInstitution(institution)"
                                          :class="{
                                            selected:
                                              institution.id ===
                                              selectedInstitution.id
                                          }"
                                          :disabled="loading"
                                        >
                                          {{ institution.full_name }}&#44;
                                          {{ institution.city }}&#44;
                                          {{ institution.state }}
                                          {{ '[' + institution.id + ']' }}
                                          <span
                                            class="float-right fa fa-check pt-1 add-icon"
                                          ></span>
                                        </b-list-group-item>
                                      </div>
                                    </b-list-group>
                                  </b-card>
                                </div>
                              </b-col>
                            </b-row>
                          </b-card>
                        </div>
                      </v-wait>
                    </div>
                  </div>
                </div>

                <div class="row list-group-container">
                  <div class="col-12">
                    <div class="row card-sub-header mt-0">
                      <div class="col-sm-6 card-sub-header">
                        <span v-if="selectedInstitutions.length">
                          Selected Institutions:
                          <i
                            >{{
                              selectedInstitutions.length | numberFormat
                            }}
                            FIs</i
                          >
                        </span>
                        <span v-else>
                          Selected Institutions
                        </span>
                      </div>
                      <div class="col-sm-6 text-right pr-0 align-self-center">
                        <b-button
                          size="sm"
                          variant="secondary"
                          @click="removeAll"
                          class="mx-3 font-weight-bold"
                          >Remove All</b-button
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div>
                      <div
                        class="selected-result rounded form-control p-0"
                        :class="{
                          'is-invalid': $v.selectedInstitutions.$error,
                          'is-valid': !$v.selectedInstitutions.$invalid
                        }"
                      >
                        <b-card class="text-center" body-class="p-0">
                          <b-row>
                            <b-col md="12">
                              <div>
                                <b-card class="text-left">
                                  <b-list-group>
                                    <div
                                      v-for="institution in selectedInstitutions"
                                      :key="'4_' + institution.id"
                                    >
                                      <b-list-group-item
                                        v-on:click.once="
                                          removeInstitution(institution)
                                        "
                                        :class="{
                                          selected:
                                            institution.id ===
                                            selectedInstitution.id
                                        }"
                                        :disabled="loading"
                                      >
                                        {{ institution.full_name }}&#44;
                                        {{ institution.city }}&#44;
                                        {{ institution.state }}
                                        {{ '[' + institution.id + ']' }}
                                        <span
                                          class="float-right fa fa-trash pt-1 remove-icon"
                                        ></span>
                                      </b-list-group-item>
                                    </div>
                                  </b-list-group>
                                </b-card>
                              </div>
                            </b-col>
                          </b-row>
                        </b-card>
                      </div>
                      <b-form-invalid-feedback
                        v-if="!$v.selectedInstitutions.required"
                        >Selected Institutions can't be
                        blank</b-form-invalid-feedback
                      >
                    </div>
                  </div>
                </div>

                <div class="flex-row align-items-top">
                  <div class="container">
                    <b-row class="list-group-container">
                      <b-col sm="2"> </b-col>
                      <b-col sm="8" class="text-center">
                        <b-button
                          size
                          variant="secondary"
                          class="fw-btn mx-3"
                          @click="closeModel"
                          >Close</b-button
                        >
                        <b-button
                          size=""
                          variant="primary"
                          class="fw-btn-ext mx-3"
                          :disabled="submittingForm"
                          v-if="!isEdit"
                          @click="saveSelectedList"
                          >Save Selected List</b-button
                        >
                        <b-button
                          size=""
                          variant="primary"
                          class="fw-btn-ext mx-3"
                          :disabled="submittingForm"
                          @click="saveSelectedList"
                          v-else
                        >
                          Update Selected List
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-list-group>
            </div>
            <b-modal
              id="peerModifiedAlert"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close
              @ok="forceCloseModal"
              title="Alert"
            >
              Changes made to Selected Institutions are not saved. Do you still
              want to close?
            </b-modal>
          </div>
        </v-wait>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import _ from 'lodash'

export default {
  name: 'selectedInstitutions',
  components: {},
  props: {
    query: {
      type: Object
    },
    rule: {
      type: Object
    },
    isMortgageLender: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      institutions: [],
      states: [],
      selectedInstitutions: [],
      searchFTType: 'both',
      fi_types: [
        { code: 'both', name: 'Both' },
        { code: 'bank', name: 'Bank' },
        { code: 'credit_union', name: 'Credit Union' }
      ],
      searchText: '',
      searchTextField: '',
      searchCity: null,
      searchCityField: null,
      searchState: null,
      selectedInstitution: {},
      offset: 0,
      loading: false,
      submittingForm: false
    }
  },
  computed: {
    emptyInstitutions() {
      return this.institutions.length === 0
    },
    selectedInstitutionIds() {
      return this.selectedInstitutions.map(si => si.id)
    },
    isEdit() {
      return this.query.value.length > 0
    }
  },
  methods: {
    setInitData() {
      this.$wait.start('fetchingFIData')
      Promise.all([
        this.fetchInstitutions(),
        this.fetchSelectedInstitutions(),
        this.fetchStateList()
      ]).then(() => {
        this.$wait.end('fetchingFIData')
      })
    },
    fetchStateList() {
      if (this.states.length) {
        return Promise.resolve()
      }
      return this.$http
        .get('/api/states', {
          handleErrors: true
        })
        .then(res => {
          this.states = res.data.states
        })
    },
    fetchSelectedInstitutions() {
      if (this.query.value.length == 0) {
        return Promise.resolve()
      }
      return this.$http
        .post(
          '/api/banks/list_by_ids',
          { ids: this.query.value },
          { handleErrors: true }
        )
        .then(res => {
          this.selectedInstitutions = res.data.financial_institutions
        })
    },
    fetchInstitutions() {
      return this.$http
        .get('/api/banks', {
          params: {
            fi_type: this.isMortgageLender ? 'mbc' : this.searchFTType
          },
          handleErrors: true
        })
        .then(res => {
          this.institutions = res.data.financial_institutions
        })
    },
    searchInstitutions: function() {
      this.$wait.start('fetchingFIs')
      this.offset = 0
      this.selectedInstitution = {}
      return this.$http
        .get('/api/banks', {
          params: {
            fi_type: this.isMortgageLender ? 'mbc' : this.searchFTType,
            search_text: this.searchText,
            city: this.searchCity,
            state: this.searchState
          },
          handleErrors: true
        })
        .then(res => {
          this.institutions = res.data.financial_institutions
        })
        .then(() => {
          this.loading = false
          this.$wait.end('fetchingFIs')
        })
    },
    search: _.debounce(function() {
      this.searchInstitutions()
    }, 500),

    setInstitution: function(institution) {
      this.selectedInstitution = institution
      if (!this.selectedInstitutionIds.includes(institution.id)) {
        let selectedIns = this.selectedInstitutions
        selectedIns.unshift(institution)
        this.selectedInstitutions = selectedIns
      }
    },
    removeInstitution: function(institution) {
      let selectedIns = this.selectedInstitutions
      selectedIns.splice(selectedIns.indexOf(institution), 1)
      this.selectedInstitutions = selectedIns
    },
    peerDataModified() {
      return !(
        this.checkPeerName &&
        this.checkPeerDescription &&
        this.checkPeerMembers
      )
    },
    closeModel: function() {
      // if (this.peerDataModified()) {
      //   this.$bvModal.show('peerModifiedAlert')
      // } else {
      this.clearData()
      this.$refs.selectInstitutions.hide()
      // }
    },
    forceCloseModal() {
      this.clearData()
      this.$refs.selectInstitutions.hide()
    },
    clearData() {
      this.searchText = ''
      this.searchTextField = ''
      this.searchCity = null
      this.searchCityField = null
      this.searchState = null
      this.pgName = null
      this.pgDescription = null
      this.selectedInstitutions = []
    },
    fetchMoreData(event) {
      let el = event.target
      if (Math.round(el.scrollTop) === el.scrollHeight - el.offsetHeight) {
        this.fetchAdditionalInstitutions()
      }
    },
    addAll() {
      let institutionsToAdd = _.unionBy(
        this.institutions,
        this.selectedInstitutions,
        'id'
      )
      this.selectedInstitutions = institutionsToAdd
    },
    removeAll() {
      this.selectedInstitutions = []
    },
    fetchAdditionalInstitutions() {
      if (this.offset < this.institutions.length) {
        this.loading = true
        this.offset = this.institutions.length
        this.$http
          .get('/api/banks', {
            params: {
              search_text: this.searchText,
              city: this.searchCity,
              state: this.searchState,
              offset: this.offset,
              fi_type: this.isMortgageLender ? 'mbc' : this.searchFTType
            },
            handleErrors: true
          })
          .then(res => {
            this.institutions = this.institutions.concat(
              res.data.financial_institutions
            )
          })
          .then(() => {
            this.loading = false
          })
      }
    },
    saveSelectedList() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submittingForm = true
        this.query.value = this.selectedInstitutionIds
        this.query.count = this.selectedInstitutionIds.length
        this.submittingForm = false
        this.closeModel()
      }
    }
  },
  watch: {
    searchTextField(newVal) {
      if (newVal !== this.searchText) {
        this.searchText = newVal
        this.search()
      }
    },
    searchCityField(newVal) {
      if (newVal !== this.searchCity) {
        this.searchCity = newVal
        this.search()
      }
    }
  },
  validations: {
    selectedInstitutions: {
      required
    }
  }
}
</script>

<style lang="scss" scoped>
.order-institute-card {
  padding: 0.7em;
}
.loading {
  opacity: 0.5;
  pointer-events: none;
}

.tip-text {
  color: #808080;
  font-weight: 350;
}

.search-form {
  width: 100%;
}
</style>
