<template>
  <b-modal
    :id="`contactSearch_${query.id}`"
    ref="contactSearch"
    size="xl"
    title="Contact Search"
    @show="search"
    hide-header
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
  >
    <div class="px-3 py-2">
      <b-row class="border-bottom mb-3">
        <h4 class="pb-2 col">Contact Search</h4>
        <label v-if="query.count" class="pull-right"
          >Matching Institutions :
          <b class="px-2 mr-5">{{ query.count }}</b></label
        >
        <span
          class="fa fa-close fa-lg px-1 pull-right"
          @click="hideModal"
        ></span>
      </b-row>
      <b-form @submit.prevent inline class="">
        <label class="px-3">Title Search Criteria</label>
        <input
          type="text"
          v-model="query.value[0]"
          @blur="search"
          class="form-control rounded col-6"
        />
        <b-button
          class="mx-4"
          type="submit"
          variant="light"
          pill
          size="sm"
          @click="search"
        >
          <span class="fa fa-search fa-lg px-3"></span>
        </b-button>
      </b-form>
      <!-- <b-row class="mb-2">
        <b-col cols="" offset="1" class="pl-5 pr-0">
          <small>
            You can also narrow down the search by specifying field.<br />
            For e.g, title: "Chairman"</small
          >
        </b-col>
      </b-row> -->
      <br />
      <v-wait for="fetchingContactSearch">
        <template slot="waiting">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="20"></content-placeholders-text>
          </content-placeholders>
        </template>
        <b-table
          striped
          sticky-header="61vh"
          :items="results.data"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          show-empty
          class="contact-search-table px-2"
        >
          <template #empty="scope">
            <div class="text-center">No Records Found.</div>
          </template>
          <template v-slot:table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{
                width:
                  field.key === 'title'
                    ? '15rem'
                    : field.key === 'state'
                    ? '4rem'
                    : field.key === 'city'
                    ? '8rem'
                    : '10rem'
              }"
            />
          </template>
          <!-- <template v-slot:cell(title)="data">
            <a :href="formatLink(data.item.url)" target="_blank">{{
              data.value
            }}</a>
          </template> -->
        </b-table>
        <b-row v-if="totalRows">
          <b-col md="6" class="my-1">
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              v-model="currentPage"
              class="my-0"
            >
            </b-pagination>
          </b-col>
        </b-row>
      </v-wait>
    </div>
  </b-modal>
</template>

<script>
// api
import contactSearchAPI from '@/api/finapps/contact_search'
// utilities
import { formatUrl } from '@/utilities'

export default {
  name: 'ContactSearch',
  components: {},
  data() {
    return {
      results: { data: [] },
      fields: ['financial_institution', 'first_name', 'last_name', 'title'],
      currentPage: 1,
      perPage: 10,
      totalRows: 0
    }
  },
  props: {
    query: {
      type: Object
    },
    rule: {
      type: Object
    },
    isMbc: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    search(event) {
      this.resetData()
      this.$wait.start('fetchingContactSearch')

      contactSearchAPI.search(this.query.value[0], this.isMbc).then(res => {
        this.results = res
        if (this.results.error) {
          let message = this.results.error.includes('Lexical')
            ? 'Please make sure the reserved characters(+ - = && || > < ! ( ) { } [ ] ^ " ~ * ? : \\ /) are used appropriately.'
            : 'Please enter a valid search criteria.'
          this.$toasted.show(message, {
            icon: 'chain-broken',
            type: 'error'
          })
        }
        if (event.type != 'show') {
          this.query.count = this.results.fi_count
          this.rule.clearResult()
        }
        this.totalRows = this.results.data.length
        this.$wait.end('fetchingContactSearch')
      })
    },
    hideModal() {
      this.$refs.contactSearch.hide()
    },
    resetData() {
      this.results = { data: [] }
      this.currentPage = 1
      this.perPage = 10
      this.totalRows = 0
    },
    formatLink(url) {
      return formatUrl(url)
    }
  }
}
</script>

<style type="scss">
.contact-search-table {
  height: 66vh;
}

.contact-search-table table td {
  padding: 0.5rem;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
