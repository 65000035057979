import axios from 'axios'

export default {
  search(searchText, isMbc) {
    return axios
      .get('/api/contact_search', {
        params: {
          search_criteria: searchText,
          is_mbc: isMbc
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
