<template>
  <b-modal
    :id="`technographicsModal-${queryID}`"
    modal-class="technographics-modal"
    size="xl"
    @shown="loadInitData()"
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    hide-footer
  >
    <v-wait for="loadingBankServices">
      <template slot="waiting">
        <content-placeholders :rounded="true" class="pt-4">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>

      <b-card class="std-border reports-card" body-class="" header-class="pl-3">
        <div slot="header">
          <div class="d-flex align-items-center">
            <span class="pr-2 cui-terminal"></span>
            <h6 class="mb-0"><b>Technographics</b></h6>
          </div>
        </div>

        <div class="row no-gutters technographics-segment pt-1">
          <div class="col-sm-3">
            <h6 class="">Technographics Categories</h6>
            <div
              class="card std-border mb-0 technographics-menu tree-menu segment-column"
            >
              <div class="card-body p-0">
                <div
                  v-for="(serviceGroup, index) in treeData"
                  :key="index"
                  class="pb-3"
                >
                  <div class="font-weight-bold text-uppercase px-2 pt-2">
                    {{ serviceGroup.text }}
                  </div>
                  <ul class="list-group">
                    <li
                      :class="[
                        {
                          active: selectedService.data === childService.data
                        },
                        'list-group-item pl-4'
                      ]"
                      v-for="(childService, index) in serviceGroup.children"
                      :key="index"
                      @click="selectService(childService, serviceGroup.text)"
                    >
                      {{ childService.text }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div :class="['col-sm-9', 'px-2 details-holder']">
            <h6 class="" v-if="selectedParent">
              {{ selectedParent }} : {{ selectedService.text }}
            </h6>
            <div class="card std-border mb-0 techno-details segment-column">
              <div class="card-body p-1">
                <div class="row p-3">
                  <div
                    class="offset-sm-4 col-sm-2 text-right align-self-center"
                  >
                    <h6 class="mb-0">Search</h6>
                  </div>

                  <div class="col-sm-6 pl-0">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Type text to search..."
                      v-model="searchText"
                    />
                  </div>
                </div>

                <LiquorTree
                  :data="treeData"
                  :multiple="false"
                  :showChildren="true"
                  :parentSelect="false"
                  :options="treeOptions"
                  :filter="searchText"
                  ref="solutionTree"
                  @node:selected="nodeSelected"
                >
                  <span class="tree-text" slot-scope="{ node }">
                    <template>
                      <span :id="'tg-' + node.id.toString()">
                        {{ node.text }}
                      </span>
                      <b-popover
                        :target="'tg-' + node.id.toString()"
                        triggers="hover"
                        placement="right"
                        boundary="window"
                        v-if="getDescription(node.data)"
                      >
                        {{ getDescription(node.data) }}
                      </b-popover>
                    </template>
                  </span>
                </LiquorTree>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-12 d-flex justify-content-center">
            <div class="px-1">
              <button
                type="button"
                class="btn btn-secondary"
                @click="$bvModal.hide(`technographicsModal-${queryID}`)"
              >
                Cancel
              </button>
            </div>
            <div class="px-1">
              <button
                type="button"
                class="btn btn-primary"
                @click="updateServiceSelection"
                :disabled="!(selectedServiceID || selectedNode)"
              >
                Set Technographic
              </button>
            </div>
          </div>
        </div>
      </b-card>
    </v-wait>
  </b-modal>
</template>

<script charset="utf-8">
// global
import { mapState, mapMutations } from 'vuex'
//api
import bankServicesAPI from '@/api/finapps/bank_services'
// ui components
import LiquorTree from 'liquor-tree'
// utilities
import { findDeep } from 'deepdash'

export default {
  name: 'TechnographicsModal',
  components: {
    LiquorTree
  },
  props: {
    queryID: {
      type: String,
      required: true
    },
    selectedServiceID: {
      required: false
    },
    segmentableElements: {
      type: Array,
      required: false
    },
    isMortgageLender: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      treeData: null,
      selectedParent: null,
      selectedService: {},
      selectedNode: null,
      searchText: null,
      treeOptions: {
        checkbox: false,
        multiple: false,
        checkOnSelect: true,
        parentSelect: true,
        autoCheckChildren: false,
        autoDisableChildren: false
      }
    }
  },
  computed: {
    ...mapState('ReportWriter', {
      bankServices: state => state.bankServices
    })
  },
  methods: {
    ...mapMutations('ReportWriter', ['setBankServices']),
    loadInitData() {
      return this.getBankServices().then(() => {
        this.treeData = this.convertToHierarichalData(this.bankServices)
        this.setDefaultService()
      })
    },
    getBankServices() {
      if (this.bankServices.length) {
        return Promise.resolve()
      }

      this.$wait.start('loadingBankServices')

      return bankServicesAPI
        .treeData(this.isMortgageLender)
        .then(res => {
          this.setBankServices(res.bank_services)
        })
        .finally(() => {
          this.$wait.end('loadingBankServices')
        })
    },
    convertToHierarichalData(services = [], id = null, link = 'parent_id') {
      return services
        .filter(service =>
          id === null
            ? !services.some(s => s.id === service[link])
            : service[link] === id
        )
        .map(service => {
          return {
            id: service.id,
            text: service.name,
            data: service,
            state: {
              expanded: true,
              checked: false,
              selected: this.serviceSelected(service),
              disabled: this.isMortgageLender
                ? this.isDisabled(service.name)
                : false,
              visible: !this.isVisible(service.id)
            },
            children: this.convertToHierarichalData(services, service.id)
          }
        })
    },
    isDisabled(name) {
      return [
        'ACH & Wire Payments',
        'Social Media',
        'Mortgage Loan Origination'
      ].includes(name)
        ? true
        : false
    },
    isVisible(id) {
      if (this.segmentableElements) {
        return this.segmentableElements.includes('' + id) ? false : true
      } else return false
    },
    setDefaultService() {
      if (this.selectedServiceID) {
        this.treeData.every(td => {
          let matchingService = null

          td.children.every(cd => {
            matchingService = findDeep(
              cd.children,
              function(bs) {
                return parseInt(bs.id) === parseInt(this.selectedServiceID)
              }.bind(this),
              {
                childrenPath: 'children'
              }
            )

            if (matchingService) {
              this.selectedParent = td.text
              this.selectedService = cd
              this.$refs.solutionTree.setModel(cd.children)
              return false
            } else {
              return true
            }
          })

          return matchingService ? false : true
        })
      } else {
        let defaultService = this.treeData[0]
        this.selectedParent = defaultService.text
        this.selectedService = defaultService.children[0]
        this.$refs.solutionTree.setModel(this.selectedService.children)
      }
    },
    serviceSelected(service) {
      if (this.selectedNode) {
        return this.selectedNode.id === service.id
      } else if (this.selectedServiceID) {
        return parseInt(this.selectedServiceID) === parseInt(service.id)
      } else {
        return false
      }
    },
    selectService(service, parentName) {
      let childServices = this.convertToHierarichalData(
        this.bankServices,
        service.id
      )

      this.searchText = null
      this.selectedParent = parentName
      this.selectedService = service
      this.$refs.solutionTree.setModel(
        childServices.length
          ? childServices
          : [
              {
                id: service.id,
                text: service.text,
                data: service,
                state: {
                  expanded: true,
                  checked: false,
                  selected: this.serviceSelected(service)
                },
                children: []
              }
            ]
      )
    },
    nodeSelected(node) {
      this.selectedNode = node
    },
    updateServiceSelection() {
      this.selectedNode.parentName = this.selectedParent
      this.selectedNode.service = this.selectedService.text
      this.$emit('updateService', this.selectedNode)
      this.$bvModal.hide(`technographicsModal-${this.queryID}`)
    },
    getDescription(nodeData) {
      return nodeData.data ? nodeData.data.description : nodeData.description
    }
  }
}
</script>

<style lang="scss"></style>
