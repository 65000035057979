import axios from 'axios'

export default {
  treeData(isMbc) {
    return axios
      .get('/api/bank_services/new_tree?is_mbc=' + isMbc, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  digitalChannelServices() {
    return axios
      .get('/api/bank_services/digital_channel_services', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  bdiTreeData(fiID) {
    return axios
      .get('/api/bank_services/bdi_tree', {
        params: {
          bank_id: fiID
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
